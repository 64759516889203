<template>
  <div class="all-container">
    <el-tabs v-model="activeTab" @tab-click="tabClick" :tab-position="'left'" class="left" v-if="tabList && tabList.length > 0">
      <el-tab-pane :label="item.activityName" :name="item.id" v-for="(item, index) in tabList" :key="index"></el-tab-pane>
    </el-tabs>
  <div class="body" v-if="displayObj && displayObj.activityCode">
    <Title>基本信息</Title>
    <el-form label-width="100px">
      <el-row>
        <el-col :span="8">
          <el-form-item label="活动编码">
            <TextEllipsis :text="displayObj.activityCode"></TextEllipsis>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="活动名称">
            <TextEllipsis :text="displayObj.activityName"></TextEllipsis>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="活动类型">
            <TextEllipsis :text="'陈列活动'"></TextEllipsis>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="活动执行时间">
            <template v-if="displayObj.activityStartTime&&displayObj.activityEndTime">
            <TextEllipsis :text="displayObj.activityStartTime + ' 至 ' +  displayObj.activityEndTime"></TextEllipsis>
            </template>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <Title>活动信息</Title>
    <el-form label-width="100px">
      <el-row>
        <el-col :span="8">
          <el-form-item label="活动执行频率">
            <TextEllipsis :text="displayObj.activityFrequency"></TextEllipsis>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="活动描述">
            <TextEllipsis :text="displayObj.activityDesc"></TextEllipsis>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="活动要求">
            <TextEllipsis :text="displayObj.activityRequireDesc"></TextEllipsis>
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item label="示例图片">
            <div v-if="displayObj.displayContent&&displayObj.displayContent.pictureList&&displayObj.displayContent.pictureList.length>0">
              <el-image class="image" v-for="(item,index) in displayObj.displayContent.pictureList"
                        :src="item.urlPathPrefix + item.urlPath"
                        :preview-src-list="[item.urlPathPrefix + item.urlPath]"
                        :key="index">
              </el-image>
            </div>
            <EmptyImage class="mb-10" v-else></EmptyImage>
          </el-form-item>
        </el-col>
       <el-col :span="24">
          <el-form-item label="陈列要求">
            <vxe-table :data="displayObj.displayContent&&displayObj.displayContent.activityProductList?displayObj.displayContent.activityProductList:[]">
              <vxe-table-column title="序号">
                <template v-slot="{rowIndex}">{{rowIndex+1}}</template>
              </vxe-table-column>
              <vxe-table-column title="系列编码" field="seriesCode"></vxe-table-column>
              <vxe-table-column title="系列名称" field="seriesName"></vxe-table-column>
              <vxe-table-column title="产品编码" field="productCode"></vxe-table-column>
              <vxe-table-column title="产品名称" field="productName"></vxe-table-column>
              <vxe-table-column title="最低陈列数" field="displayNum"></vxe-table-column>
            </vxe-table>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <Title>执行结果</Title>
    <div v-if="displayObj.activityRequireReqVoList&&displayObj.activityRequireReqVoList.length>0">
      <el-form label-width="100px">
        <el-form-item :label="item.activityRequireName" v-for="(item, index) in displayObj.activityRequireReqVoList" :key="index">
          <div v-if="item.pictureList&&item.pictureList.length>0">
            <el-image class="image" v-for="(pItem, pIndex) in item.pictureList"
                      :src="pItem.urlPathPrefix + pItem.urlPath"
                      :preview-src-list="[pItem.urlPathPrefix + pItem.urlPath]"
                      :key="pIndex">
            </el-image>
          </div>
          <EmptyImage class="mb-10" v-else></EmptyImage>
        </el-form-item>
      </el-form>
      <!-- <el-image v-for="(item,index) in displayObj.executionTypeList"
                :src="item.picPath+item.picUrl"
                :preview-src-list="displayObj.executionTypeList"
                :key="index">
      </el-image> -->
    </div>
    <!-- <EmptyImage v-else></EmptyImage> -->
    <div class="mt-20">
      <ExtendForm :value="displayObj"></ExtendForm>
    </div>
  </div>
  </div>
</template>

<script>
import TextEllipsis from '../../../../../../components/text_ellipsis/index.vue';
import Title from '../../../../../../components/title';
import EmptyImage from '../../../../../../components/empty';
import request from '../../../../../../utils/request';
/** @desc 扩展表单 */
import ExtendForm from './extend_form.vue';

const isObject = (value) => Object.prototype.toString.call(value) === '[object Object]';
export default {
  enName: '陈列活动',
  components: {
    TextEllipsis, Title, EmptyImage, ExtendForm,
  },
  props: {
    value: Object,
  },
  watch: {
    value: {
      handler(curVal) {
        if (curVal && curVal.id) {
          this.id = curVal.id;
          this.activityType = curVal.activityType;
          this.stepCode = curVal.stepCode;
          this.getTabList();
        }
        // if (curVal && curVal.stepDisplayActivityExecution && isObject(curVal.stepDisplayActivityExecution)) {
        //   this.displayObj = {
        //     ...this.displayObj,
        //     ...curVal.stepDisplayActivityExecution,
        //     visitPictureList: curVal.stepDisplayActivityExecution.visitPictureList || [], // 示例图片
        //     activityProductList: curVal.stepDisplayActivityExecution.activityProductList || [], // 活动产品
        //     executionTypeList: curVal.stepDisplayActivityExecution.executionTypeList || [], // 活动执行结果
        //   };
        // }
      },
      immediate: true,
      deep: true,
    },
  },
  data() {
    return {
      id: '',
      activityType: '',
      stepCode: '',
      activeTab: '',
      tabList: [],
      displayObj: {},
    };
  },
  methods: {
    // tab切换
    tabClick(tab) {
      this.getData(tab.name);
    },
    // 获取tab数据
    getTabList() {
      const params = {
        id: this.id,
        activityType: this.activityType,
        stepCode: this.stepCode,
      };
      request.post('/sfa/sfaVisitController/findVisitActivityExecutionList', params).then((res) => {
        if (!res.success || !res.result) return false;
        this.tabList = res.result || [];
        if (this.tabList && this.tabList.length > 0) {
          this.activeTab = this.tabList[0].id;
          this.getData(this.tabList[0].id);
        }
      });
    },
    // 获取活动执行结果数据
    getData(activityId) {
      const params = {
        visitPlanInfoId: this.id,
        activityType: this.activityType,
        activityExecutionId: activityId,
      };
      request.post('/sfa/sfaWorkDailyController/loadActExecutionEditPageForWorkbench', params).then((res) => {
        if (!res.success || !res.result) return false;
        this.displayObj = res.result;
        let activityRequireNames = [];
        const { activityRequireReqVoList } = res.result;
        if (activityRequireReqVoList && activityRequireReqVoList.length > 0) {
          activityRequireNames = activityRequireReqVoList.map((v) => (v.activityRequireName));
        }
        this.displayObj.activityRequireDesc = activityRequireNames.join('、');
      });
    },
  },
};
</script>
<style lang="less" scoped>
/deep/ .el-tabs--left{
  overflow: initial;
}
.all-container{
  display: flex;
  flex-direction: row;
  align-items: stretch;
  .body{
    flex: 1;
  }
}
.image{
  width: 160px;
  height: 160px;
}
.mt-20{
  margin-top: 20px;
}
.mb-10{
  margin-bottom: 10px;
}
</style>
