<template>
  <div>
    <vxe-table :data="competObj.sfaVisitStepColletItemReqVos">
      <vxe-table-column title="序号">
        <template v-slot="{rowIndex}">{{rowIndex+1}}</template>
      </vxe-table-column>
      <vxe-table-column title="所属品牌" field="brand"></vxe-table-column>
      <vxe-table-column title="所属系列" field="series"></vxe-table-column>
      <vxe-table-column title="SKU" field="sku"></vxe-table-column>
      <vxe-table-column title="铺货数量" field="quantity"></vxe-table-column>
    </vxe-table>
    <div>活动信息：{{competObj.activityInfo||''}}</div>
    <div>
      <span>图片：</span>
      <div v-if="competObj.sfaVisitPictureReqVos.length>0">
        <el-image class="image" v-for="(item,index) in competObj.sfaVisitPictureReqVos"
                  :src="item.urlPathPrefix + item.urlPath"
                  :preview-src-list="[item.urlPathPrefix + item.urlPath]"
                  :key="index">
        </el-image>
      </div>
      <EmptyImage class="mb-10" v-else></EmptyImage>
    </div>
  </div>
</template>

<script>
import EmptyImage from '../../../../../../components/empty';

const isObject = (value) => Object.prototype.toString.call(value) === '[object Object]';

export default {
  enName: '竞品采集',
  components: { EmptyImage },
  props: {
    value: Object,
  },
  watch: {
    value: {
      handler(curVal) {
        if (curVal && isObject(curVal)) {
          this.competObj = {
            ...this.competObj,
            ...curVal,
            sfaVisitPictureReqVos: curVal.sfaVisitPictureReqVos || [],
            sfaVisitStepColletItemReqVos: curVal.sfaVisitStepColletItemReqVos || [],
          };
        }
      },
      immediate: true,
    },
  },
  data() {
    return {
      competObj: {
        sfaVisitPictureReqVos: [], // 图片信息
        sfaVisitStepColletItemReqVos: [], // 竞品详细列表
      },
    };
  },
};
</script>
<style lang="less" scoped>
.image{
  width: 160px;
  height: 160px;
}
.mb-10{
  margin-bottom: 10px;
}
</style>
