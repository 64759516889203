<template>
  <div>
    <el-form label-width="150px">
      <el-row :gutter="20">
        <el-col :span="8" v-for="(item, index) in dataObj.helpScoreDetailList||[]" :key="index">
          <el-form-item :label="item.stepName">
            <TextEllipsis :text="item.qualifiedName"></TextEllipsis>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col :span="24">
          <el-form-item label="拜访内容">
            <el-input v-model="dataObj.helpDefenseSummary" type="textarea" :autosize="{ minRows: 4 }" readonly></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item label="重点问题记录">
            <el-input v-model="dataObj.keyQuestions" type="textarea" :autosize="{ minRows: 4 }" readonly></el-input>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <div class="mt-20">
      <ExtendForm :value="dataObj"></ExtendForm>
    </div>
  </div>
</template>

<script>
import TextEllipsis from '../../../../../../components/text_ellipsis';
import request from '../../../../../../utils/request';
/** @desc 扩展表单 */
import ExtendForm from './extend_form.vue';

const isObject = (value) => Object.prototype.toString.call(value) === '[object Object]';

export default {
  enName: '协访评价',
  components: { TextEllipsis, ExtendForm },
  props: {
    value: Object,
  },
  watch: {
    value: {
      handler(curVal) {
        if (curVal && isObject(curVal)) {
          console.log(curVal);
          this.id = curVal.id;
          this.clientCode = curVal.clientCode;
          this.activityType = curVal.activityType;
          this.stepCode = curVal.stepCode;
          this.getData();
        }
      },
      immediate: true,
      deep: true,
    },
  },
  data() {
    return {
      // 协访评价的数据
      dataObj: {},
    };
  },
  methods: {
    getData() {
      request.post('/sfa/SfaVisitHelpController/loadInfoPageData', { bizId: this.id, visitPlanInfoId: this.id, stepCode: this.stepCode }).then((res) => {
        if (!res.success || !res.result) return false;
        this.dataObj = res.result || {};
      });
    },
  },
};
</script>
