<template>
  <div>
    <Title>常规货架陈列</Title>
    <template>
      <div v-if="storeCheckObj.regularShelfDisplayList.length > 0">
        <el-image class="image" v-for="(item, index) in storeCheckObj.regularShelfDisplayList"
                 :src="item.urlPathPrefix + item.urlPath"
                 :preview-src-list="[item.urlPathPrefix + item.urlPath]"
                 :key="index">
        </el-image>
      </div>
      <EmptyImage class="mb-10" v-else></EmptyImage>
    </template>

    <Title>物料陈列</Title>
    <template>
      <div v-if="storeCheckObj.materialDisplayList.length > 0">
        <el-image class="image" v-for="(item, index) in storeCheckObj.materialDisplayList"
                  :src="item.urlPathPrefix + item.urlPath"
                  :preview-src-list="[item.urlPathPrefix + item.urlPath]"
                  :key="index">
        </el-image>
      </div>
      <EmptyImage class="mb-10" v-else></EmptyImage>
    </template>

    <Title>价格签</Title>
    <template>
      <div v-if="storeCheckObj.priceList.length > 0">
        <el-image class="image" v-for="(item, index) in storeCheckObj.priceList"
                  :src="item.urlPathPrefix + item.urlPath"
                  :preview-src-list="[item.urlPathPrefix + item.urlPath]"
                  :key="index">
        </el-image>
      </div>
      <EmptyImage class="mb-10" v-else></EmptyImage>
    </template>
  </div>
</template>

<script>
import EmptyImage from '../../../../../../components/empty';
import Title from '../../../../../../components/title';

const isObject = (value) => Object.prototype.toString.call(value) === '[object Object]';

export default {
  enName: '店面检查',
  components: { EmptyImage, Title },
  props: {
    value: Object,
  },
  watch: {
    value: {
      handler(curVal) {
        if (curVal && isObject(curVal)) {
          this.storeCheckObj = {
            ...this.storeCheckObj,
            regularShelfDisplayList: curVal.regularShelfDisplayList || [],
            materialDisplayList: curVal.materialDisplayList || [],
            priceList: curVal.priceList || [],
          };
        }
      },
      immediate: true,
    },
  },
  data() {
    return {
      storeCheckObj: {
        regularShelfDisplayList: [], // 常规货架陈列
        materialDisplayList: [], // 物料陈列
        priceList: [], // 价格签
      },
    };
  },
};
</script>
<style lang="less" scoped>
.image{
  width: 160px;
  height: 160px;
}
.mb-10{
  margin-bottom: 10px;
}
</style>
