<template>
  <div>
    <div v-for="(item, index) in list" :key="index">
      <Title>{{item.modelName}}</Title>
      <el-form :label-width="labelWidth?labelWidth:''">
        <el-row>
          <el-col :span="8" v-for="(pItem, pIndex) in item.sfaVisitStepFromControlRespVoList" :key="pIndex">
            <el-form-item :label="pItem.controlName">
              <template v-if="pItem.controlType === 'sfa_control_type_attachment'">
                <div v-if="data[pItem.fieldName+'Name']&&data[pItem.fieldName+'Name'].length>0">
                  <el-image class="image" v-for="(iItem,iIndex) in data[pItem.fieldName+'Name']"
                            :src="iItem"
                            :preview-src-list="data[pItem.fieldName+'Name']"
                            :key="iIndex">
                  </el-image>
                </div>
                <EmptyImage v-else></EmptyImage>
              </template>
              <template v-else>
                <TextEllipsis :text="data[pItem.fieldName+'Name']?data[pItem.fieldName+'Name']:data[pItem.fieldName]"></TextEllipsis>
              </template>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </div>
  </div>
</template>
<script>
import Title from '../../../../../../components/title';
import TextEllipsis from '../../../../../../components/text_ellipsis';
import EmptyImage from '../../../../../../components/empty';

export default {
  components: {
    Title,
    TextEllipsis,
    EmptyImage,
  },
  props: {
    labelWidth: {
      type: String,
      default: '',
    },
    value: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  watch: {
    value: {
      deep: true,
      immediate: true,
      handler(val) {
        if (val && Object.keys(val).length > 0) {
          this.data = val;
          if (val.sfaVisitStepFrom && Object.keys(val.sfaVisitStepFrom).length > 0) {
            this.list = val.sfaVisitStepFrom.stepFromModelRespVoList || [];
            this.list.map((item) => {
              const v = item;
              if (v.sfaVisitStepFromControlRespVoList && v.sfaVisitStepFromControlRespVoList.length > 0) {
                v.sfaVisitStepFromControlRespVoList.map((pItem) => {
                  const m = pItem;
                  if (m.extendParam) {
                    const extendParam = JSON.parse(m.extendParam);
                    let options = [];
                    if (m.controlType === 'select') {
                      options = extendParam.options;
                      m.options = options ? JSON.parse(options) : [];
                    } else if (m.controlType === 'checkbox_single') {
                      options = extendParam.checkbox_single_options;
                      m.options = options ? JSON.parse(options) : [];
                    } else if (m.controlType === 'checkbox_mult') {
                      options = extendParam.checkbox_mult_options;
                      m.options = options ? JSON.parse(options) : [];
                    } else if (m.controlType === 'sfa_control_type_attachment') {
                      const value = this.data[`${m.fieldName}`];
                      let newValue = [];
                      if (typeof (value) === 'string') {
                        newValue = JSON.parse(value);
                      } else if (Array.isArray(value)) {
                        newValue = value;
                      }
                      const newValueList = [];
                      newValue.map((nv) => {
                        let newValItem = nv;
                        if (Object.prototype.toString.call(nv) === '[object Object]') {
                          if (nv.picUrl && nv.picPath) {
                            newValItem = nv.picUrl + nv.picPath;
                          }
                          newValItem = nv.urlPathPrefix + nv.urlPath;
                        }
                        newValueList.push(newValItem);
                        return nv;
                      });
                      this.data[`${m.fieldName}Name`] = newValueList;
                    }
                    if (m.options && m.options.length > 0) {
                      const codes = this.data[m.fieldName] ? this.data[m.fieldName].split(',') : [];
                      const names = [];
                      m.options.map((cItem) => {
                        const o = cItem;
                        if (this.data[m.fieldName] && m.controlType === 'checkbox_mult') {
                          codes.map((n) => {
                            if (o.key === n) {
                              names.push(o.val);
                            }
                            return n;
                          });
                          this.data[`${m.fieldName}Name`] = names.join(',');
                        } else if (o.key === this.data[m.fieldName]) {
                          this.data[`${m.fieldName}Name`] = o.val;
                        }
                        return o;
                      });
                    }
                  }
                  return m;
                });
              }
              return v;
            });
          }
        }
      },
    },
  },
  data() {
    return {
      list: [],
      data: {},
    };
  },
};
</script>
<style lang="less" scoped>
.image{
  width: 160px;
  height: 160px;
}
</style>
