<template>
  <div class="all-container">
    <el-tabs v-model="activeTab" @tab-click="tabClick" :tab-position="'left'" class="left" v-if="tabList && tabList.length > 0">
      <el-tab-pane :label="item.activityName" :name="item.activityCode" v-for="(item, index) in tabList" :key="index"></el-tab-pane>
    </el-tabs>
  <div class="body" v-if="activityObj && activityObj.activityCode">
    <Title>基本信息</Title>
    <el-form label-width="100px">
      <el-row>
        <el-col :span="8">
          <el-form-item label="活动编码">
            <TextEllipsis :text="activityObj.activityCode"></TextEllipsis>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="活动名称">
            <TextEllipsis :text="activityObj.activityName"></TextEllipsis>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="活动类型">
            <TextEllipsis :text="activityObj.activityTypeDesc"></TextEllipsis>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="陈列类型">
            <TextEllipsis :text="activityObj.displayTypeDesc"></TextEllipsis>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="兑付方式">
            <TextEllipsis :text="activityObj.paymentMethodDesc"></TextEllipsis>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="活动状态">
            <TextEllipsis :text="activityObj.activityTypeDesc"></TextEllipsis>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="活动开始时间">
            <TextEllipsis :text="activityObj.activityStartTime"></TextEllipsis>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="活动结束时间">
            <TextEllipsis :text="activityObj.activityEndTime"></TextEllipsis>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <Title>商品数据</Title>
    <div class="product-container" v-if="activityObj.sfaActSchemeRespVo&&activityObj.sfaActSchemeRespVo.actSchemeSetmealList.length>0">
      <template v-for="(item, index) in activityObj.sfaActSchemeRespVo.actSchemeSetmealList">
        <div :key="index">
          <div class="product-title">套餐{{index+1}}</div>
          <div class="product-title-item">本品</div>
          <vxe-table class="product-table" :data="item.bpSchemeProductList">
            <vxe-table-column title="序号" width="100px">
              <template v-slot="{rowIndex}">{{rowIndex+1}}</template>
            </vxe-table-column>
            <vxe-table-column title="产品编码" field="productCode" min-width="100px" show-overflow></vxe-table-column>
            <vxe-table-column title="产品名称" field="productName" min-width="100px" show-overflow></vxe-table-column>
            <vxe-table-column title="单价" field="price" min-width="100px" show-overflow></vxe-table-column>
            <vxe-table-column title="单位" field="unitName" min-width="100px" show-overflow></vxe-table-column>
            <vxe-table-column title="数量" field="quantity" min-width="100px" show-overflow></vxe-table-column>
          </vxe-table>
          <div class="product-title-item">赠品</div>
          <vxe-table class="product-table" :data="item.zpSchemeProductList">
            <vxe-table-column title="序号" width="100px">
              <template v-slot="{rowIndex}">{{rowIndex+1}}</template>
            </vxe-table-column>
            <vxe-table-column title="产品编码" field="productCode" min-width="100px" show-overflow></vxe-table-column>
            <vxe-table-column title="产品名称" field="productName" min-width="100px" show-overflow></vxe-table-column>
            <vxe-table-column title="单价" field="price" min-width="100px" show-overflow></vxe-table-column>
            <vxe-table-column title="单位" field="unitName" min-width="100px" show-overflow></vxe-table-column>
            <vxe-table-column title="数量" field="quantity" min-width="100px" show-overflow></vxe-table-column>
          </vxe-table>
        </div>
      </template>
    </div>
    <div v-for="(item, index) in pictureList" :key="index">
      <Title>{{item.displayTypeDesc}}陈列照</Title>
      <div v-if="item.pictureRespVos&&item.pictureRespVos.length>0">
        <el-image class="image" v-for="(pItem, pIndex) in item.pictureRespVos"
                  :src="pItem.urlPathPrefix + pItem.urlPath"
                  :preview-src-list="[pItem.urlPathPrefix + pItem.urlPath]"
                  :key="pIndex">
        </el-image>
      </div>
      <EmptyImage class="mb-10" v-else></EmptyImage>
    </div>
    <div class="mt-20">
      <ExtendForm :value="activityObj"></ExtendForm>
    </div>
  </div>
  </div>
</template>

<script>
import TextEllipsis from '../../../../../../components/text_ellipsis/index.vue';
import Title from '../../../../../../components/title';
import EmptyImage from '../../../../../../components/empty';
import request from '../../../../../../utils/request';
/** @desc 扩展表单 */
import ExtendForm from './extend_form.vue';

const isObject = (value) => Object.prototype.toString.call(value) === '[object Object]';
export default {
  enName: '陈列活动',
  components: {
    TextEllipsis, Title, EmptyImage, ExtendForm,
  },
  props: {
    value: Object,
  },
  watch: {
    value: {
      handler(curVal) {
        if (curVal && curVal.id) {
          this.id = curVal.id;
          this.clientCode = curVal.clientCode;
          this.activityType = curVal.activityType;
          this.stepCode = curVal.stepCode;
          this.getTabList();
        }
      },
      immediate: true,
      deep: true,
    },
  },
  data() {
    return {
      id: '',
      clientCode: '',
      activityType: '',
      stepCode: '',
      activeTab: '',
      tabList: [],
      activityObj: {},
      pictureList: [],
    };
  },
  methods: {
    // tab切换
    tabClick(tab) {
      this.getData(tab.name);
    },
    // 获取tab数据
    getTabList() {
      const params = {
        visitPlanInfoId: this.id,
        terminalCode: this.clientCode,
      };
      request.post('/sfa/sfaAsExecutionController/findActSchemePosApplyByVisitPlanInfoId', params).then((res) => {
        if (!res.success || !res.result) return false;
        this.tabList = res.result || [];
        if (this.tabList && this.tabList.length > 0) {
          this.activeTab = this.tabList[0].activityCode;
          this.getData(this.tabList[0].activityCode);
        }
      });
    },
    // 获取活动执行结果数据
    getData(activityCode) {
      const params = {
        visitPlanInfoId: this.id,
        terminalCode: this.clientCode,
        activityCode,
      };
      request.post('/sfa/sfaAsExecutionController/queryExecutionDetailByActivityCodeAndTerminalCode', params).then((res) => {
        if (!res.success || !res.result) return false;
        this.activityObj = res.result.actSchemePosApply;
        this.pictureList = [];
        if (res.result.asExecutionList && res.result.asExecutionList.length > 0) {
          res.result.asExecutionList.map((v) => {
            this.pictureList = this.pictureList.concat(v.asExecutionPictureTopVos);
            return v;
          });
        }
      });
    },
  },
};
</script>
<style lang="less" scoped>
/deep/ .el-tabs--left{
  overflow: initial;
}
.all-container{
  display: flex;
  flex-direction: row;
  align-items: stretch;
  .body{
    flex: 1;
  }
}
.image{
  width: 160px;
  height: 160px;
}
.mt-20{
  margin-top: 20px;
}
.mb-10{
  margin-bottom: 10px;
}
.product-container{
  padding: 10px;
  margin-bottom: 20px;
  background-color: #f6f7fb;
  .product-title{
    font-size: 16px;
  }
  .product-title-item{
    font-size: 14px;
  }
  .product-table{
    margin-bottom: 10px;
  }
}
</style>
