<template>
  <div>
    <el-form label-width="150px">
      <el-row>
        <el-col :span="6" v-for="(item,index) in visitDetailStep" :key="index">
          <el-form-item :label="item.formName">
            <TextEllipsis :text="item.formValue"></TextEllipsis>
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item label="拜访小结">
            <el-input v-model="visitSummary" type="textarea" :autosize="{ minRows: 4 }" readonly></el-input>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <el-tabs v-model="activeTab" type="card" @tab-click="tabClick">
      <el-tab-pane v-for="(item,index) in componentArr" :label="item.tabName" :name="item.tabIndex" :key="index">
        <template v-if="item.isSuccess === '1'">
          <component :is="item.componentName" :value="item.propsValue"></component>
          <div class="mt-20">
            <ExtendForm :value="item.propsValue"></ExtendForm>
          </div>
        </template>
        <template v-else>
          <div class="mt-20">该拜访步骤暂未完成！</div>
        </template>
      </el-tab-pane>

    </el-tabs>
  </div>
</template>

<script>
import request from '../../../../../../utils/request';
/** @desc 扩展表单 */
import ExtendForm from './extend_form.vue';
/** @desc 进店 */
import InStore from './in_store.vue';
/** @desc 离店 */
import OutStore from './out_store.vue';
/** @desc 订单采集 */
import OrderCollection from './order_collection.vue';
/** @desc 库存盘点 */
import InventoryChecking from './inventory_checking.vue';
/** @desc 竞品采集 */
import CompetitiveProductCollection from './competitive_collection.vue';
/** @desc 活动执行 */
import CostActivity from './cost_activity.vue';
/** @desc 陈列活动 */
import DisplayActivity from './display_activity.vue';
/** @desc 店面检查 */
import StoreCheck from './store_check.vue';
/** @desc 方案活动 */
import ProgramActivity from './program_activity.vue';
/** @desc tpm活动 */
import TpmActivity from './tpm_activity.vue';
/** @desc 协访评价 */
import HelpScore from './helpScore.vue';

import TextEllipsis from '../../../../../../components/text_ellipsis/index.vue';

const isObject = (value) => Object.prototype.toString.call(value) === '[object Object]';

const stepObj = {
  inStore: {
    tabName: '进店打卡', tabIndex: 'inStore', componentName: 'InStore', propsValue: {},
  },
  order: {
    tabName: '订单采集', tabIndex: 'order', componentName: 'OrderCollection', propsValue: {},
  },
  stock: {
    tabName: '库存盘点', tabIndex: 'stock', componentName: 'InventoryChecking', propsValue: {},
  },
  competitor: {
    tabName: '竞品采集', tabIndex: 'competitor', componentName: 'CompetitiveProductCollection', propsValue: {},
  },
  storeCheck: {
    tabName: '店面检查', tabIndex: 'storeCheck', componentName: 'StoreCheck', propsValue: {},
  },
  display: {
    tabName: '陈列活动', tabIndex: 'display', componentName: 'DisplayActivity', propsValue: {},
  },
  cost: {
    tabName: '费用活动', tabIndex: 'cost', componentName: 'CostActivity', propsValue: {},
  },
  outStore: {
    tabName: '离店打卡', tabIndex: 'outStore', componentName: 'OutStore', propsValue: {},
  },
  def_step_template: {
    tabName: '方案活动', tabIndex: 'def_step_template', componentName: 'ProgramActivity', propsValue: {},
  },
  tpm: {
    tabName: 'tpm活动', tabIndex: 'tpm', componentName: 'TpmActivity', propsValue: {},
  },
  helpScore: {
    tabName: '协访评价', tabIndex: 'helpScore', componentName: 'HelpScore', propsValue: {},
  },
};

export default {
  components: {
    ExtendForm,
    InStore,
    OutStore,
    OrderCollection,
    InventoryChecking,
    CompetitiveProductCollection,
    CostActivity,
    TextEllipsis,
    DisplayActivity,
    StoreCheck,
    ProgramActivity,
    TpmActivity,
    HelpScore,
  },
  props: {
    value: Object,
  },
  watch: {
    value: {
      handler(curVal) {
        this.id = curVal.id || '';
        this.clientCode = curVal.clientCode || '';
        // todo 1.通过步骤来判断，展示哪些具体的组件,同时取对应的步骤的数据给传下去。
        let componentArr = [];
        if (curVal && curVal.step && Array.isArray(curVal.step)) {
          componentArr = curVal.step.map((item, index) => ({
            ...item, ...stepObj[item.pageCode], propsValue: item.data || null,
          }));
          // curVal.step.forEach((item) => {
          //   console.log(item, item.componentName);
          //   // if (item.componentName) {
          //   componentArr.push({
          //     ...item,
          //     ...stepObj[item.pageCode],
          //     propsValue: item.data || null,
          //   });
          //   // }
          // });
        }
        // if (curVal && curVal.roleDirectoryRespVos && Array.isArray(curVal.roleDirectoryRespVos)) {
        //   componentArr = curVal.roleDirectoryRespVos.map((item, index) => {
        //     let propsValue = curVal.roleDirectoryRespVos[index];
        //     if (item.directoryCode === 'inStore') {
        //       propsValue = {
        //         inStore: curVal.inStore, // 进店时间
        //         inStoreAddress: curVal.inStoreAddress, // 进店地址
        //         inStorePicList: curVal.inStorePicList || [], // 进店图片
        //       };
        //     }
        //     if (item.directoryCode === 'outStore') {
        //       propsValue = {
        //         outStore: curVal.outStore, // 离店时间
        //         outStoreAddress: curVal.outStoreAddress, // 离店地址
        //         outStorePicList: curVal.outStorePicList || [], // 离店
        //       };
        //     }
        //     return {
        //       ...stepObj[item.directoryCode], propsValue,
        //     };
        //   });
        // }

        console.log('拜访汇总=========打印', componentArr);
        this.componentArr = componentArr.filter((cc) => cc.componentName);
        // todo 2.取默认的第一个组件为激活的数据
        if (this.componentArr.length > 0) {
          this.activeTab = componentArr[0].tabIndex;
        }
        this.visitSummary = curVal.visitSummary;
        // todo 3.设置是否下单，是否盘库等展示
        if (curVal.visitDetailStep && isObject(curVal.visitDetailStep)) {
          const visitDetailStep = [];
          // eslint-disable-next-line guard-for-in
          for (const key in curVal.visitDetailStep) {
            visitDetailStep.push({ formName: key, formValue: curVal.visitDetailStep[key] });
          }
          this.visitDetailStep = visitDetailStep;
        }
      },
    },
  },
  data() {
    return {
      id: '',
      clientCode: '',
      // 面板切换
      activeTab: '',
      // 动态组件
      componentArr: [],
      // 拜访小结
      visitSummary: '',
      // 展示Form的信息
      visitDetailStep: [],
    };
  },
  methods: {
    // 点击tab切换
    tabClick(tab) {
      const { index } = tab;
      const {
        propsValue, tabIndex, stepCode, isSuccess, data,
      } = this.componentArr[index];
      if (isSuccess === '1' && (!data || Object.keys(data).length === 0)) {
        // 如果步骤列表没有返回数据，则掉详情接口获取
        // console.log(tab);
        if (tabIndex === 'storeCheck') {
          // 店面检查
          this.getStoreCheckData(stepCode);
        } else if (tabIndex === 'display') {
          // 陈列活动
          this.tabChange('display', stepCode);
        } else if (tabIndex === 'cost') {
          // 费用活动
          this.tabChange('cost', stepCode);
        } else if (tabIndex === 'order') {
          // 订单采集
          this.getOrderData(stepCode);
        } else if (tabIndex === 'competitor') {
          // 竞品采集
          this.getCompetitorData(stepCode);
        } else if (tabIndex === 'stock') {
          // 库存盘点
          this.getStockData(stepCode);
        } else if (tabIndex === 'def_step_template') {
          // 方案活动
          this.tabChange('def_step_template', stepCode);
        } else if (tabIndex === 'tpm') {
          // tpm活动
          this.tabChange('tpm', stepCode);
        } else if (tabIndex === 'helpScore') {
          // 协访评价
          this.tabChange('helpScore', stepCode);
        }
      }
    },
    // 获取店面检查数据
    getStoreCheckData(stepCode) {
      request.post('/sfa/sfaWorkDailyController/loadEditPageForWorkbenchStoreCheck', { bizId: this.id, visitPlanInfoId: this.id, stepCode }).then((res) => {
        if (!res.success || !res.result) return false;
        const index = this.componentArr.findIndex((val) => val.tabIndex === 'storeCheck');
        this.$set(this.componentArr[index], 'propsValue', res.result);
      });
    },
    // 点击陈列活动/费用/方案活动/tpm活动/协访评价
    tabChange(activityType, stepCode) {
      const propsValue = {
        id: this.id,
        time: new Date().getTime(),
        activityType,
        stepCode,
        clientCode: this.clientCode,
      };
      const index = this.componentArr.findIndex((val) => val.tabIndex === activityType);
      this.$set(this.componentArr[index], 'propsValue', propsValue);
    },
    // 获取订单采集数据
    getOrderData(stepCode) {
      request.post('/sfa/sfaWorkDailyController/loadEditPageForWorkbenchOrder', { bizId: this.id, visitPlanInfoId: this.id, stepCode }).then((res) => {
        if (!res.success || !res.result) return false;
        const index = this.componentArr.findIndex((val) => val.tabIndex === 'order');
        this.$set(this.componentArr[index], 'propsValue', res.result);
      });
    },
    // 获取竞品采集数据
    getCompetitorData(stepCode) {
      request.post('/sfa/sfaWorkDailyController/loadEditPageForWorkbenchCompetitor', { bizId: this.id, visitPlanInfoId: this.id, stepCode }).then((res) => {
        if (!res.success || !res.result) return false;
        const index = this.componentArr.findIndex((val) => val.tabIndex === 'competitor');
        this.$set(this.componentArr[index], 'propsValue', res.result);
      });
    },
    // 获取库存盘点数据
    getStockData(stepCode) {
      request.post('/sfa/sfaWorkDailyController/loadInfoPageForWorkbenchStockInventory', { bizId: this.id, visitPlanInfoId: this.id, stepCode }).then((res) => {
        if (!res.success || !res.result) return false;
        const index = this.componentArr.findIndex((val) => val.tabIndex === 'stock');
        this.$set(this.componentArr[index], 'propsValue', res.result);
      });
    },
  },
};
</script>
<style lang="less" scoped>
.mt-20{
  margin-top: 20px;
}
</style>
