<template>
  <el-form label-width="100px">
    <el-row>
      <el-col :span="12">
        <el-form-item label="离店时间">
          <TextEllipsis :text="outStoreObj.storeDateTime"></TextEllipsis>
        </el-form-item>
      </el-col>
      <el-col :span="12">
        <el-form-item label="离店地址">
          <TextEllipsis :text="outStoreObj.storeAddress"></TextEllipsis>
        </el-form-item>
      </el-col>
      <el-col :span="24">
        <el-form-item label="离店打卡图片">
          <div v-if="outStoreObj.storePics.length>0">
            <el-image class="image" v-for="(item,index) in outStoreObj.storePics"
                      :src="item.urlPathPrefix + item.urlPath"
                      :preview-src-list="[item.urlPathPrefix + item.urlPath]"
                      :key="index">
            </el-image>
          </div>
          <EmptyImage v-else></EmptyImage>
        </el-form-item>
      </el-col>
    </el-row>
  </el-form>
</template>

<script>
import TextEllipsis from '../../../../../../components/text_ellipsis';
import EmptyImage from '../../../../../../components/empty';

const isObject = (value) => Object.prototype.toString.call(value) === '[object Object]';

export default {
  enName: '离店打卡',
  components: { TextEllipsis, EmptyImage },
  props: {
    value: Object,
  },
  watch: {
    value: {
      handler(curVal) {
        if (curVal && isObject(curVal)) {
          this.outStoreObj = {
            ...this.outStoreObj,
            ...curVal,
            // inStorePicList: curVal.inStorePicList || [],
            // outStorePicList: curVal.outStorePicList || [],
          };
        }
      },
      immediate: true,
    },
  },
  data() {
    return {
      // 离店的数据
      outStoreObj: {
        storeDateTime: '', // 离店时间
        storeAddress: '', // 离店地址
        storePics: [], // 离店
      },
    };
  },
};
</script>
<style lang="less" scoped>
.image{
  width: 160px;
  height: 160px;
}
</style>
